


























import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';

@Component({
  name: 'WithdrawalStep',
})
export default class FailedOperation extends Vue {
  i18n_namespace = 'components.allianz-dashboard.errors.transaction_error';

  @PropSync('stepName', { type: String, required: true })
  step!: string;

  @PropSync('currentStep', { type: Boolean, required: true })
  current_step!: boolean;

  @PropSync('stepCompleted', { type: Boolean, required: true })
  step_completed!: boolean;
}

